import axios from "axios";

const request = axios.create({
  baseURL: process.env.VUE_APP_API || "https://dev.api.aishows.cn",
  timeout: 60 * 1000,
})

request.interceptors.request.use(config  => {
  let token = localStorage.getItem("token");
  if (token && token.length > 0) {
    token = "Bearer " + token
    config.headers.Authorization = token
  }
  return config;
}, error => {
  return Promise.reject(error)
})

request.interceptors.response.use(response => {
    const data = response.data
    if (data.code == 200) {
      return data
    } else {
      return Promise.reject(data)
    }
}, error => {
  if (error.response?.data) {
    return Promise.reject(error.response.data)
  }
  return Promise.reject(error)
})

export default request