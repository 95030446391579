<template>
  <div class="login-box">
    <div class="login-form">
      <div>Aishows社区后台管理系统</div>

      <div>
        <br />
        <el-input v-model="phone" auto-complete="username" placeholder="请输入账号"></el-input>
      </div>

      <div>
        <br />
        <el-input v-model="password" auto-complete="password" placeholder="密码" show-password></el-input>
      </div>

      <div>
        <br />
        <el-button type="primary" @click="login">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import conf from "@/assets/js/conf.js"
export default {
  name: "LoginCom",
  data() {
    return {
      phone: "",
      password: ""
    }
  },
  methods: {
    login() {
      this.$axios({
        url: conf.api.signIn,
        method: "post",
        data: {
          phone: this.phone,
          password: this.password
        }
      }).then(res => {
        if (res && res.data && res.data.code == 200) {
          localStorage.setItem("token", res.data.data.token)
          window.location.href = "/"
        }
      }).catch(err => {
        this.$message.error(err.data.message)
      })
    }
  }
}
</script>

<style scoped>
.login-box {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.login-form {
  width: 300px;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
}
</style>