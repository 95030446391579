<template>
  <div id="app">

    <LoginCom v-if="!$store.state.loginUser.id > 0" @loginOut="loginOut" />

    <el-container v-else style="height: 100vh;">

      <el-aside width="200px">
        <div class="logo-box">
          <div>
            <img src="../public/logo.png" />
          </div>
        </div>

        <el-menu
          :default-active="defaultActive"
          :default-openeds="['2']"
          router
        >
          <el-menu-item index="/user">
            <i class="el-icon-user-solid"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>

          <el-submenu index="2">
            <template slot="title"><i class="el-icon-menu"></i>应用商店管理</template>
            <el-menu-item index="/appCat">应用分类</el-menu-item>
            <el-menu-item index="/app">应用管理</el-menu-item>
          </el-submenu>

          <el-menu-item index="/production/list">
            <i class="el-icon-picture"></i>
            <span slot="title">作品管理</span>
          </el-menu-item>

          <el-menu-item index="/cash/list">
            <i class="el-icon-s-finance"></i>
            <span slot="title">提现审核</span>
          </el-menu-item>

          <el-menu-item index="/ad/setting">
            <i class="el-icon-s-finance"></i>
            <span slot="title">广告分成</span>
          </el-menu-item>

        </el-menu>
      </el-aside>

      <el-container>
        <el-header style="text-align: right; font-size: 12px">

          <el-dropdown @command="handleLoginUser">
            <span class="el-dropdown-link pointer">
              {{ $store.state.loginUser.nickname }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </el-header>

        <el-main>
          <router-view />
        </el-main>
      </el-container>

    </el-container>

  </div>
</template>

<script>
import LoginCom from "@/components/LoginCom.vue"
import conf from '@/assets/js/conf'
export default {
  components: {
    LoginCom
  },
  data() {
    return {
      hasToken: false,
      defaultActive: ''
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(nVal) {
        this.defaultActive = nVal
      }
    }
  },
  mounted() {
    let token = localStorage.getItem("token")
    var _this = this
    if (token) {
      this.hasToken = true
      // 获取用户信息
      this.$axios({
        url: conf.api.userInfo,
        method: "get",
      }).then(res => {
        if (res.data.code == 200 && res.data.data.role == 'ADMIN') {
          _this.$store.commit("setLoginUser", res.data.data)
        } else {
          localStorage.removeItem("token")
          _this.$message.error("sorry,您暂无权限！");
        }
      })
    }
  },
  methods: {
    handleLoginUser(val) {
      if (val == "loginOut") {
        this.loginOut()
      }
    },
    loginOut() {
      localStorage.removeItem("token")
      this.hasToken = false
      var loginUser = {
        "id": 0,
        "nickname": "",
        "avatar": ""
      }
      this.$store.commit("setLoginUser", loginUser)
    }
  }
}
</script>

<style>
.el-menu {
  background: none !important;
  border-right: 0px !important;
}

.logo-box {
  width: 100%;
  height: 70px;
  font-size: 25px;
  line-height: 70px;
  text-align: center;
}

.el-header {
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  background: rgb(238, 241, 246);
}
</style>