import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/app',
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/UserView.vue')
  },
  {
    path: '/app',
    name: 'App',
    component: () => import('../views/AppView.vue')

  },
  {
    path: '/app/edit',
    name: 'AppEditView',
    component: () => import('../views/AppEditView.vue')

  },
  {
    path: '/appCat',
    name: 'AppCatView',
    component: () => import('../views/AppCatView.vue')

  },
  {
    path: '/appCat/edit',
    name: 'AppCatEditView',
    component: () => import('../views/AppCatEditView.vue')
  },
  {
    path: '/production/list',
    name: 'ProductionList',
    component: () => import('../views/production/ProductionList.vue')
  },
  {
    path: '/production/detail/:id/:useType',
    name: 'ProductionDetail',
    component: () => import('../views/production/ProductionDetail.vue')
  },
  {
    path: '/cash/list',
    name: 'CashList',
    component: () => import('../views/cash/CashList.vue')
  },
  {
    path: '/ad/setting',
    name: 'AdSetting',
    component: () => import('../views/ad/adSetting.vue')
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
