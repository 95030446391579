export default {
  api: {
    signIn: "/user/sign-in",                      // 登录
    userInfo: "/user/mine",                       // 查询个人信息

    getQiniuToken: "/personal/file/token",        // 七牛云上传凭证

    appCatList: "/app/class/list",                // 查询应用分类列表
    appCatSave: "/app/class/save",                // 创建应用分类
    appCatUp: "/app/class/update",                // 更新应用分类
    appCatSort: "/app/class/sort",                // 排序应用分类
    appCatRm: "/app/class/delete",                // 移除应用分类

    appList: "/app/page",                         // 查询应用列表
    appInfo: "/app/detail",                       // 查询应用详情
    appUp: "/app/update",                         // 更新应用
    appSave: "/app/create",                       // 创建应用
    appRm: "/app/delete",                         // 移除应用

    userList: "/user/page",                       // 查询用户列表

    getProductions: "/works/page",                // 查询作品列表
    getProductionInfo: "/works/detail",           // 查询作品详情
    auditProduction: "/works/audit",              // 审核作品

    getCashs: "/account/withdraw/audit/page",     // 查询提现申请列表
    auditCash: "/account/withdraw/audit",         // 审核提现
    getDownloadPrice: "/account/price",           // 获取下载单价
    getDownloadCount: "/account/download-count",  // 获取下载次数
    getShareList: "/account/share/page",          // 获取分成记录列表
    setDownloadPrice: "/account/price/update",    // 修改下载单价
  },
  // 七牛云图片加载规则
  compresRules: {
    // 审核列表头像，作品封面
    listCoverRule: "?imageView2/1/w/300/format/webp/q/85",
  },
  // 工具类型
  toolTypes: {
    0: { label: 'SD', icon: '/static/tool_sd.png' },
    1: { label: 'Midjourney', icon: '/static/tool_midjourney.png' },
    2: { label: 'Adobe Firefly', icon: '/static/tool_adobe.png' },
    3: { label: '其他', icon: '/static/tool_default.png' },
  },
  async getQiniuToken(axios, ext) {
    const res = await axios.get(this.api.getQiniuToken + "?ext=" + ext);
    if (res.data && res.data.code == 200) {
      return res.data.data
    } else {
      return {
        uploadToken: "",
        fileId: "",
        key: ""
      }
    }
  },
}