import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser:{
      "id": 0,
      "nickname": "",
      "avatar": ""
    }
  },
  getters: {
  },
  mutations: {
    setLoginUser(state,payload){
      state.loginUser = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
